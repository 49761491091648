$(document).ready(function(){

    $(".chosen-select").on("chosen:showing_dropdown", function () {
        $(this).parents("div").css("overflow", "visible");
    });

    $(":input").inputmask();

    $('#pickup_date').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    }).on('changeDate', function(e)
        {
            // if ($('#return_date').val() == "")
            // {
            //     $('#return_date').datepicker('setStartDate', e.date);
            //     $('#return_date').datepicker('setDate', e.date);
            // }
        });

    $('#return_date').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('#driver_birthday').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('#driver_license_date').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('#pickup_time').timepicker({
        minuteStep: 15,
        showMeridian: false
    });

    $('#return_time').timepicker({
        minuteStep: 15,
        showMeridian: false
    });

    $('#flight_time').timepicker({
        minuteStep: 1,
        showMeridian: false,
        defaultTime: '00:00'
    });

    $('#flight_date').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('.input-daterange').datepicker({
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('#prolongation_date').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd'
    });

    $('#prolongation_time').timepicker({
        minuteStep: 15,
        showMeridian: false
    });

    checkExtraAeroporto();

    $('#upgrade').click( function()
      {
        var isChecked = $('#upgrade').is(":checked");

        $("#upgradeAdditionalFields").prop('required',isChecked);

        if(isChecked)
        {
          $('#vehicle').css('display','none');
          $('#upgradeAdditionalFields').css('display','block');
          $('#upgradeAdditionalFields .chosen-container').css('width','100%');
        }
        else
        {
          $('#vehicle').css('display','block');
          $('#upgradeAdditionalFields').css('display','none');
        }

      });

    $('#numberManualContract').on('input', function()
      {

        if($('#numberManualContract').val() != null && $('#numberManualContract').val()!='')
        {
          $('#divManualContract').css('display','block');
          $("#manualContract").prop('required',true);
        }
        else
        {
          $('#divManualContract').css('display','none');
          $("#manualContract").prop('required',false);
        }

      });

    $('#extra_airport').click( function()
      {
        checkExtraAeroporto();
      });

    $('#kms_unlimited').on('click', function() {

        var isChecked = $('#kms_unlimited').is(":checked");
        $('#kms_included').prop('disabled', isChecked);

        if( isChecked )
        {
            $('#kms_included').attr('data-parsley-required', false);
        }
        else
        {
            $('#kms_included').attr('data-parsley-required', true);
        }


    });

    $('#driver_license_number').on('blur', function()
      {
        if($('#driver_license_number').val()!= '')
        {
          $.ajax({
            type: "GET",
            url: "/condutores/" + $('#driver_license_number').val(),
            success: function(data){
              driver = jQuery.parseJSON(data)[0];
              if( typeof driver === "object" )
              {
                if( driver.undesired==1)
                {
                  var confirmar = confirm('Código do erro: CND \n\rContacte o administrador \n\rCarregue em "OK" para mais informações?');
                  if(confirmar == true)
                  {
                    alert( driver.undesired_justify );
                  }
                }
                else
                {
                  $('#driver_id').val(driver.id);
                  $('#driver_name').val(driver.name);
                  $('#driver_mobile_phone').val(driver.mobile_phone);
                  $('#driver_birthday').val(driver.birthday);
                  $('#driver_license_date').val(driver.license_date);
                  $('#driver_license_country').val(driver.license_country);
                  $('#driver_identification_card_number').val(driver.identification_card_number);
                  $('#driver_address').val(driver.address);
                  $('#driver_zipCode').val(driver.zip_code);
                  $('#driver_city').val(driver.city);
                  $('#driver_country').val(driver.country_id);
                  $('#driver_tax_number').val(driver.tax_number);
                  $('#driver_email').val(driver.email);
                  $('#city_emission_identification_document').val(driver.identification_card_city_emission);
                }
              }
            },
            error: function()
            {
              $('#driver').find('input:text').val('');
            }
          });
        }
        else
        {
            $('#driver_id').val('');
            $('#driver_name').val('');
            $('#driver_mobile_phone').val('');
            $('#driver_birthday').val('');
            $('#driver_license_date').val('');
            $('#driver_license_country').val(1);
            $('#driver_identification_card_number').val('');
            $('#driver_address').val('');
            $('#driver_zipCode').val('');
            $('#driver_city').val('');
            $('#driver_country').val(1);
            $('#driver_tax_number').val('');
            $('#driver_email').val('');
            $('#city_emission_identification_document').val('');
        }
      });

    $('#billing_value').focusout(function(){

      if(this.value != $('#total_value').val() && this.value != '') {

        $('#div_value_discrepancy_justify').show();
        $("#value_discrepancy_justify").prop('required',true);

      } else {

        $('#div_value_discrepancy_justify').hide();
        $("#value_discrepancy_justify").prop('required',false);
      }
    });

    $('#billing_tax_number').on('blur', function()
      {
        if($('#billing_tax_number').val() !== '')
        {
          $.ajax({
            type: "GET",
            url: "/billingfield/" + $('#billing_tax_number').val(),
            success: function(data){
              billingfield = jQuery.parseJSON(data)[0];

              if( typeof billingfield === "object" )
              {
                $('#billing_name').val(billingfield.billing_name);
                $('#billing_address').val(billingfield.billing_address);
                $('#billing_zipcode').val(billingfield.billing_zipcode);
                $('#billing_city').val(billingfield.billing_city);
                $('#billing_country').val(billingfield.billing_country);
              }
            },
            error: function()
            {
            }
          });
        }
      });

    $('#additional_driver_license_number').on('blur', function()
      {
        $.ajax({
          type: "GET",
          url: "/condutores/" + $('#additional_driver_license_number').val(),
          success: function(data){
            driver = jQuery.parseJSON(data)[0];

            if(driver.undesired==1)
            {
              var confirmar = confirm('Código do erro: CND \n\rContacte o administrador \n\rCarregue em "OK" para mais informações?');

              if(confirmar == true)
              {
                alert( driver.undesired_justify );
              }
              $('#additional_driver_id').val('');
              $('#additional_driver_name').val('');
              $('#additional_driver_mobile_phone').val('');
              $('#additional_driver_birthday').val('');
              $('#additional_driver_license_date').val('');
              $('#additional_driver_identification_number').val('');
              $('#additional_driver_address').val('');
              $('#additional_driver_zipCode').val('');
              $('#additional_driver_city').val('');
              $('#additional_driver_license_country').val('');
              $('#additional_driver_country').val(1);
              $('#additional_driver_tax_number').val('');
            }
            else
            {
              $('#additional_driver_id').val(driver.id);
              $('#additional_driver_name').val(driver.name);
              $('#additional_driver_mobile_phone').val(driver.mobile_phone);
              $('#additional_driver_birthday').val(driver.birthday);
              $('#additional_driver_license_date').val(driver.license_date);
              $('#additional_driver_identification_number').val(driver.identification_card_number);
              $('#additional_driver_address').val(driver.address);
              $('#additional_driver_zipCode').val(driver.zip_code);
              $('#additional_driver_city').val(driver.city);
              $('#additional_driver_license_country').val(driver.license_country);
              $('#additional_driver_country').val(driver.country_id);
              $('#additional_driver_tax_number').val(driver.tax_number);
            }
          },
          error: function()
          {
            $('#additional_driver_id').val('');
            $('#additional_driver_name').val('');
            $('#additional_driver_mobile_phone').val('');
            $('#additional_driver_birthday').val('');
            $('#additional_driver_license_date').val('');
            $('#additional_driver_identification_number').val('');
            $('#additional_driver_address').val('');
            $('#additional_driver_zipCode').val('');
            $('#additional_driver_city').val('');
            $('#additional_driver_license_country').val('');
            $('#additional_driver_country').val(1);
            $('#additional_driver_tax_number').val('');
          }

        });
      });


    $('#quote-button').on('click', function()
    {
        $('#basic-info .overlay').show();
    });

    $('#reservation-button').on('click', function()
    {
        $('#driver-info .overlay').show();
    });

    $('#save-contract-button').on('click', function()
    {
        $('.overlay').show();
    });

});

function printPage(id) {

    var printContents = document.getElementById(id).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}


function checkExtraAeroporto(){
  var isChecked = $('#extra_airport').is(":checked");

  $("#flightNumberGroup").prop('required',isChecked);

  if(isChecked)
  {
    $('#flightNumberGroup').css('display','block');
    $('#flightDateGroup').css('display','block');
  }
  else
  {
    $('#flightNumberGroup').val('');
    $('#flightDateGroup').val('');
    $('#flightNumberGroup').css('display','none');
    $('#flightDateGroup').css('display','none');
  }
}

$('.panel-group').on('shown.bs.collapse', function () {
    $('.chosen-manual').chosen();
    $('.daterange').daterangepicker({
        locale: {
            cancelLabel: 'Limpar',
            applyLabel: 'Ok',
            format: 'YYYY/MM/DD'
        },
        autoUpdateInput: false
    }).
        on('cancel.daterangepicker', function(ev, picker) {
            $(ev.target).val('');
        }).
        on('apply.daterangepicker', function(ev, picker) {
            console.log(picker);
            $(ev.target).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
        });
});

$('.daterange').daterangepicker({
    locale: {
        cancelLabel: 'Limpar',
        applyLabel: 'Ok',
        format: 'YYYY/MM/DD'
    },
    autoUpdateInput: false
}).
    on('cancel.daterangepicker', function(ev, picker) {
        $(ev.target).val('');
    }).
    on('apply.daterangepicker', function(ev, picker) {
        console.log(picker);
        $(ev.target).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
    });


$('.datepicker').datepicker({
    autoclose: true,
    todayHighlight: true,
    format: 'yyyy/mm/dd'
});
